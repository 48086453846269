import React from 'react';
import { Search as SearchIcon } from 'lucide-react';
import { SearchContainer, SearchWrapper, SearchInput, SearchIcon as StyledSearchIcon } from './styles';

interface SearchProps {
  value: string;
  onChange: (value: string) => void;
}

export const Search: React.FC<SearchProps> = ({ value, onChange }) => (
  <SearchContainer>
    <SearchWrapper>
      <StyledSearchIcon>
        <SearchIcon size={18} />
      </StyledSearchIcon>
      <SearchInput
        type="text"
        placeholder="Search vehicles..."
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </SearchWrapper>
  </SearchContainer>
);