import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { Settings, Pencil, Sigma, Cpu } from 'lucide-react';
import { IVehicleData } from '../../types/vehicle';
import {
  Container,
  TableContainer,
  StyledTable,
  Th,
  Td,
  Tr,
  Resizer,
  NoDataContainer,
  DeviceInfo,
  ChipInfo,
  Checkbox,
  ColumnSelector,
  ColumnSelectorButton,
  ColumnSelectorDropdown,
  ColumnSelectorItem,
  ActionButton,
  InfoContainer,
  SelectButton,
  VehicleInfo
} from './styles';
import { Search } from './Search';
import { getListVehicles } from '../../services/api';
import LoadingIndicator from '../../../../components/LoadingIndicator';

interface Column {
  key: keyof IVehicleData | 'vehicle' | 'actions';
  label: string;
  width?: string;
  sortable?: boolean;
}

interface VehicleTableProps {
  onEditVehicle?: (vehicle: IVehicleData) => void;
  onDeviceClick?: (vehicle: IVehicleData) => void;
  onChipClick?: (vehicle: IVehicleData) => void;
}

export const VehicleTable: React.FC<VehicleTableProps> = ({
  onEditVehicle,
  onDeviceClick,
  onChipClick
}) => {
  const [vehicles, setVehicles] = useState<IVehicleData[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedVehicles, setSelectedVehicles] = useState<number[]>([]);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof IVehicleData;
    direction: 'asc' | 'desc';
  } | null>(null);

  const columns: Column[] = [
    { key: 'id', label: 'ID', width: '80px', sortable: true },
    // { key: 'actions', label: 'Ações', width: '60px' },
    { key: 'vehicle', label: 'Veículo', sortable: true },
    { key: 'device_imei', label: 'Equipamento' },
    { key: 'sim_serial', label: 'Chip' },
    { key: 'client_name', label: 'Cliente', sortable: true },
    { key: 'last_transmission', label: 'Comunicação', width: '180px', sortable: true },
    { key: 'vehicle_registration_date', label: 'Data Cadastro', width: '180px', sortable: true },
    { key: 'plan_title', label: 'Plano', width: '150px', sortable: true },
    { key: 'connected_users_count', label: 'Usuários', width: '80px', sortable: true }
  ];

  const [visibleColumns, setVisibleColumns] = useState<Set<string>>(
    new Set(columns.map(col => col.key))
  );

  useEffect(() => {
    fetchVehicles();
  }, []);

  const fetchVehicles = async () => {
    try {
      const data = await getListVehicles();
      setVehicles(data);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key: keyof IVehicleData) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedVehicles = useCallback(() => {
    if (!sortConfig) return vehicles;

    return [...vehicles].sort((a, b) => {
      const aValue = a[sortConfig.key] ?? '';
      const bValue = b[sortConfig.key] ?? '';
      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [vehicles, sortConfig]);

  const filteredVehicles = sortedVehicles().filter(vehicle =>
    Object.values(vehicle).some(value =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const formatDate = (date: string | null) => {
    if (!date) return '-';
    return format(new Date(date), 'dd/MM/yyyy HH:mm');
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedVehicles(filteredVehicles.map(v => v.id));
    } else {
      setSelectedVehicles([]);
    }
  };

  const handleSelectVehicle = (id: number) => {
    setSelectedVehicles(prev => {
      if (prev.includes(id)) {
        return prev.filter(vehicleId => vehicleId !== id);
      }
      return [...prev, id];
    });
  };

  const toggleColumn = (columnKey: string) => {
    setVisibleColumns(prev => {
      const newSet = new Set(prev);
      if (newSet.has(columnKey)) {
        newSet.delete(columnKey);
      } else {
        newSet.add(columnKey);
      }
      return newSet;
    });
  };

  const handleDeviceClick = (vehicle: IVehicleData) => {
    if (onDeviceClick) {
      onDeviceClick(vehicle);
    }
  };

  const handleChipClick = (vehicle: IVehicleData) => {
    if (onChipClick) {
      onChipClick(vehicle);
    }
  };

  const handleEditClick = (vehicle: IVehicleData) => {
    if (onEditVehicle) {
      onEditVehicle(vehicle);
    }
  };

  if (loading) return <LoadingIndicator message={"Carregando veículos"} />;

  return (
    <Container>
      <div className="flex justify-between items-center mb-4">
        <Search value={searchTerm} onChange={setSearchTerm} />
        <ColumnSelector>
          <ColumnSelectorButton onClick={() => setShowColumnSelector(!showColumnSelector)}>
            <Settings size={18} />
            Colunas
          </ColumnSelectorButton>
          {showColumnSelector && (
            <ColumnSelectorDropdown>
              {columns.map(column => (
                <ColumnSelectorItem key={column.key}>
                  <Checkbox
                    checked={visibleColumns.has(column.key)}
                    onChange={() => toggleColumn(column.key)}
                  />
                  <span>{column.label}</span>
                </ColumnSelectorItem>
              ))}
            </ColumnSelectorDropdown>
          )}
        </ColumnSelector>
      </div>
      <TableContainer>
        <StyledTable>
          <thead>
            <tr>
              <Th className="checkbox-column">
                <Checkbox
                  checked={selectedVehicles.length === filteredVehicles.length && filteredVehicles.length > 0}
                  onChange={handleSelectAll}
                />
              </Th>
              {columns.map(column => (
                visibleColumns.has(column.key) && (
                  <Th
                    key={column.key}
                    width={column.width}
                    onClick={() => column.sortable && handleSort(column.key as keyof IVehicleData)}
                    style={{ cursor: column.sortable ? 'pointer' : 'default' }}
                    className={column.key === 'actions' ? 'actions-column' : ''}
                  >
                    {column.label}
                    {column.key !== 'actions' && <Resizer />}
                  </Th>
                )
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredVehicles.length > 0 ? (
              filteredVehicles.map((vehicle) => (
                <Tr key={vehicle.id}>
                  <Td className="checkbox-column">
                    <Checkbox
                      checked={selectedVehicles.includes(vehicle.id)}
                      onChange={() => handleSelectVehicle(vehicle.id)}
                    />
                  </Td>
                  {visibleColumns.has('id') && <Td>{vehicle.id}</Td>}
                  {/* {visibleColumns.has('actions') && (
                    <Td className="actions-column">
                      <ActionButton onClick={() => handleEditClick(vehicle)}>
                        <Pencil size={18} />
                      </ActionButton>
                    </Td>
                  )} */}
                  {visibleColumns.has('vehicle') && (
                    <Td>
                      <InfoContainer onClick={() => handleEditClick(vehicle)}>
                        <Pencil size={14} className="text-gray-400" />
                        <VehicleInfo>
                          <span className="vehicle-plate">{vehicle.vehicle_plate}</span>
                          <span className="vehicle-model">{vehicle.vehicle_model_name}</span>
                        </VehicleInfo>
                      </InfoContainer>
                    </Td>
                  )}
                  {visibleColumns.has('device_imei') && (
                    <Td>
                      {vehicle.device_imei ? (
                        <InfoContainer onClick={() => handleDeviceClick(vehicle)}>
                          <Pencil size={14} className="text-gray-400" />
                          <DeviceInfo>
                            <span className="device-id">{vehicle.device_imei}</span>
                            <span className="device-manufacturer">{vehicle.model_reference} - {vehicle.manufacturer_name}</span>
                          </DeviceInfo>
                        </InfoContainer>
                      ) : (
                        <SelectButton onClick={() => handleDeviceClick(vehicle)}>
                          <Cpu size={16} />
                          <span>Selecionar Equipamento</span>
                        </SelectButton>
                      )}
                    </Td>
                  )}
                  {visibleColumns.has('sim_serial') && (
                    <Td>
                      {vehicle.sim_serial ? (
                        <InfoContainer onClick={() => handleChipClick(vehicle)}>
                          <Pencil size={14} className="text-gray-400" />
                          <ChipInfo>
                            <span className="chip-serial">{vehicle.sim_serial}</span>
                            <span className="chip-details">{vehicle.sim_operator} - {vehicle.sim_provider_name}</span>
                          </ChipInfo>
                        </InfoContainer>
                      ) : (
                        <SelectButton onClick={() => handleChipClick(vehicle)}>
                          <Sigma size={16} />
                          <span>Selecionar Chip</span>
                        </SelectButton>
                      )}
                    </Td>
                  )}
                  {visibleColumns.has('client_name') && <Td>{vehicle.client_name}</Td>}
                  {visibleColumns.has('last_transmission') && <Td>{formatDate(vehicle.last_transmission)}</Td>}
                  {visibleColumns.has('vehicle_registration_date') && <Td>{formatDate(vehicle.vehicle_registration_date)}</Td>}
                  {visibleColumns.has('plan_title') && <Td>{vehicle.plan_title || '-'}</Td>}
                  {visibleColumns.has('connected_users_count') && <Td>{vehicle.connected_users_count}</Td>}
                </Tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length + 1}>
                  <NoDataContainer>
                    Nenhum veículo encontrado
                  </NoDataContainer>
                </td>
              </tr>
            )}
          </tbody>
        </StyledTable>
      </TableContainer>
    </Container>
  );
};