import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const TabHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const CompactSummary = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.75rem 1rem;
  background: #f9fafb;
  border-radius: 0.5rem;
  width: fit-content;
  min-width: 420px;

  .summary-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    
    .label {
      font-size: 0.75rem;
      color: #6b7280;
    }
    
    .value {
      font-size: 0.875rem;
      font-weight: 600;
      color: #111827;
      
      &.success {
        color: #059669;
      }
      
      &.warning {
        color: #dc2626;
      }
    }
  }

  .divider {
    width: 1px;
    height: 2rem;
    background: #e5e7eb;
  }
`;

export const ActionBar = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const BulkActionSelect = styled.select`
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #374151;
  background-color: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #6366f1;
    box-shadow: 0 0 0 1px #6366f1;
  }

  &:disabled {
    background-color: #f3f4f6;
    cursor: not-allowed;
  }
`;

export const NewInvoiceButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: #4b5563;
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: #374151;
  }
`;

export const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  background: white;
  color: #6b7280;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #f3f4f6;
    color: #111827;
  }

  &:disabled {
    background: #f3f4f6;
    cursor: not-allowed;
  }

  .spinning {
    animation: ${spin} 1s linear infinite;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;

  th {
    text-align: left;
    padding: 0.625rem 0.75rem;
    color: #6b7280;
    font-weight: 500;
    border-bottom: 1px solid #e5e7eb;
    background: #f9fafb;
    white-space: nowrap;
  }

  td {
    padding: 0.625rem 0.75rem;
    border-bottom: 1px solid #e5e7eb;
    white-space: nowrap;
  }

  tbody tr {
    cursor: pointer;

    &:hover {
      background: #f9fafb;
    }
  }

  input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
    border-radius: 0.25rem;
    border: 1px solid #d1d5db;
    cursor: pointer;

    &:checked {
      background-color: #6366f1;
      border-color: #6366f1;
    }
  }
`;

export const InvoiceStatus = styled.span<{ status: 'paid' | 'pending', dueDate: string }>`
  display: inline-flex;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: ${props => {
    if (props.status === 'paid') return '#dcfce7';
    const today = new Date();
    const dueDate = new Date(props.dueDate);
    return dueDate > today ? '#dbeafe' : '#fee2e2';
  }};
  color: ${props => {
    if (props.status === 'paid') return '#166534';
    const today = new Date();
    const dueDate = new Date(props.dueDate);
    return dueDate > today ? '#1e40af' : '#991b1b';
  }};
  white-space: nowrap;
`;

export const AlertIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    &.alert {
      color: #9ca3af;
    }

    &.block {
      color: #dc2626;
    }
  }
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  color: #4b5563;
  font-size: 0.875rem;
  cursor: pointer;
  margin-bottom: 1rem;

  &:hover {
    background: #f9fafb;
    color: #111827;
  }
`;