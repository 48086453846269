import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  padding: 1.5rem;
  background-color: #f9fafb;
  display: flex;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  max-width: 1400px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border: none;
  background: transparent;
  color: #4b5563;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #111827;
  }
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border: none;
  background: transparent;
  color: #4b5563;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    color: #111827;
  }
`;

export const ClientInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
    margin: 0;
  }

  .cpf {
    color: #6b7280;
    font-size: 0.875rem;
    padding-left: 1rem;
    border-left: 2px solid #e5e7eb;
  }
`;

export const ClientCard = styled.div`
  background: white;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  .info-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    padding: 1.25rem;
    border-bottom: 1px solid #e5e7eb;
  }

  .info-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .info-item {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;

    .icon {
      color: #6366f1;
      flex-shrink: 0;
      margin-top: 0.25rem;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    .label {
      font-size: 0.75rem;
      color: #6b7280;
    }

    .value {
      font-size: 0.875rem;
      color: #111827;
    }
  }
`;

export const StatusBadge = styled.span<{ status: string }>`
  display: inline-flex;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: ${props => props.status === 'PAGO' ? '#dcfce7' : '#fee2e2'};
  color: ${props => props.status === 'PAGO' ? '#166534' : '#991b1b'};
`;

export const TabsContainer = styled.div`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

export const TabList = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e5e7eb;
`;

export const TabButton = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  border: none;
  background: ${props => props.active ? '#f3f4f6' : 'transparent'};
  border-radius: 0.375rem;
  color: ${props => props.active ? '#111827' : '#6b7280'};
  font-weight: ${props => props.active ? '500' : 'normal'};
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #f3f4f6;
  }

  .spinning {
    animation: ${spin} 1s linear infinite;
    margin-left: 0.5rem;
  }
`;

export const TabContent = styled.div`
  padding: 1rem;
  overflow-x: auto;
`;