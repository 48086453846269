import React, { useState } from 'react';
import { Users, Package } from 'lucide-react';
import { ClientsTab } from './components/ClientsTab';
import { PlansTab } from './components/PlansTab';
import * as S from './styles';

function AppFinancesV2() {
  const [activeTab, setActiveTab] = useState<'clients' | 'plans'>('clients');

  return (
    <div className="min-h-screen bg-gray-50">
      <S.TabList>
        <S.TabButton
          active={activeTab === 'clients'}
          onClick={() => setActiveTab('clients')}
        >
          <Users size={18} />
          Clientes
        </S.TabButton>
        <S.TabButton
          active={activeTab === 'plans'}
          onClick={() => setActiveTab('plans')}
        >
          <Package size={18} />
          Planos
        </S.TabButton>
      </S.TabList>

      <div className="p-4">
        {activeTab === 'clients' && <ClientsTab />}
        {activeTab === 'plans' && <PlansTab />}
      </div>
    </div>
  );
}

export default AppFinancesV2;