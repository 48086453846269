
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import { Tab, Box } from '@mui/material';
import ListClientsTab from './Views/ListClientsTab';
import ImportClientsTab from './Views/ImportClientsTab';
import VehiclesView from '../Vehicles/Views/VehiclesView';
import ImportDevicesTab from '../Devices/Views/ImportDevicesTab';
import ImportVehiclesTab from './Views/ImportVehiclesTab';
import UsersPage from '../Users/UsersPage';
import GEXPReader from './Views/importGeofences';
import App from '../VehiclesV2/App';



const ClientsPage: React.FC = () => {

    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                        <Tab label="Clientes" value="1" />
                        <Tab label="Veículos" value="2" />
                        <Tab label="Usuários" value="6" />
                        <Tab label="Importar Clientes" value="3" />
                        <Tab label="Importar Equipamentos" value="4" />
                        <Tab label="Importar Veículos" value="5" />
                        <Tab label="Veículos V2" value="7" />
                    </TabList>
                </Box>
                <div>
                    <TabPanel value="1"><ListClientsTab /></TabPanel>
                    <TabPanel value="2"><VehiclesView /></TabPanel>
                    <TabPanel value="3"><ImportClientsTab /></TabPanel>
                    <TabPanel value="4"><ImportDevicesTab /></TabPanel>
                    <TabPanel value="5"><ImportVehiclesTab /></TabPanel>
                    <TabPanel value="6"><UsersPage /></TabPanel>
                    <TabPanel value="7"><App /></TabPanel>
                </div>
            </TabContext>
        </>
    );
}
export default ClientsPage