import { httpFinances } from '../../../gateways/Axios';
import { Client } from '../types/Client';
import { Invoice } from '../types/Invoice';
import { Vehicle } from '../types/Vehicle';

const mockInvoices: Invoice[] = [
  {
    id: 77116,
    idempresa: 49,
    idcliente: 27,
    status_system: "wait",
    boletoid: 0,
    pix_txid: "0",
    transaction_id: null,
    code: 2,
    dt_status: "2025-03-23T11:00:00.000Z",
    amount: 663.0999999999998,
    amountPay: 0,
    dt_venc: "2025-03-25T03:00:00.000Z",
    AvisoDia: 5,
    pago: 0,
    dt_pay: null,
    pay_type: null,
    dt_emissao: "2025-03-23T11:00:00.000Z",
    dt_alert: "2025-04-03T11:00:00.000Z",
    dt_block: "2025-03-23T11:00:00.000Z",
    FeeDay: 330,
    FeeMonth: 150,
    isErr: 0,
    MsgError: "",
    obs: "",
    descr: "Fatura de Março",
    IsSendMail: 0,
    typeCobr: 1,
    unique_id: "675ec5a9-1c0b-47a9-81ac-f1d75afbad09",
    is_ger_billet: 0,
    delete_at: null,
    update_at: null,
    is_sync: 2,
    totalDias: 11,
    diasToAlert: 2,
    diasToBlock: 11
  },
  {
    id: 77117,
    idempresa: 49,
    idcliente: 27,
    status_system: "paid",
    boletoid: 1,
    pix_txid: "1",
    transaction_id: "tx_123",
    code: 2,
    dt_status: "2025-02-23T11:00:00.000Z",
    amount: 450.00,
    amountPay: 450.00,
    dt_venc: "2025-02-25T03:00:00.000Z",
    AvisoDia: 5,
    pago: 1,
    dt_pay: "2025-02-24T10:00:00.000Z",
    pay_type: "pix",
    dt_emissao: "2025-02-23T11:00:00.000Z",
    dt_alert: "2025-03-03T11:00:00.000Z",
    dt_block: "2025-02-23T11:00:00.000Z",
    FeeDay: 330,
    FeeMonth: 150,
    isErr: 0,
    MsgError: "",
    obs: "",
    descr: "Fatura de Fevereiro",
    IsSendMail: 1,
    typeCobr: 1,
    unique_id: "775ec5a9-1c0b-47a9-81ac-f1d75afbad10",
    is_ger_billet: 1,
    delete_at: null,
    update_at: null,
    is_sync: 2,
    totalDias: 30,
    diasToAlert: 0,
    diasToBlock: 0
  },
  {
    id: 77118,
    idempresa: 49,
    idcliente: 27,
    status_system: "wait",
    boletoid: 0,
    pix_txid: "0",
    transaction_id: null,
    code: 2,
    dt_status: "2025-04-01T11:00:00.000Z",
    amount: 550.00,
    amountPay: 0,
    dt_venc: "2025-04-10T03:00:00.000Z",
    AvisoDia: 5,
    pago: 0,
    dt_pay: null,
    pay_type: null,
    dt_emissao: "2025-04-01T11:00:00.000Z",
    dt_alert: "2025-04-15T11:00:00.000Z",
    dt_block: "2025-04-01T11:00:00.000Z",
    FeeDay: 330,
    FeeMonth: 150,
    isErr: 0,
    MsgError: "",
    obs: "",
    descr: "Fatura de Abril",
    IsSendMail: 0,
    typeCobr: 1,
    unique_id: "875ec5a9-1c0b-47a9-81ac-f1d75afbad11",
    is_ger_billet: 0,
    delete_at: null,
    update_at: null,
    is_sync: 2,
    totalDias: 5,
    diasToAlert: 10,
    diasToBlock: 5
  }
];

const mockVehicles: Vehicle[] = [
  {
    equip_imei: "354522182171673",
    txdtEquip: "2025-04-05T23:55:12.000Z",
    veiculo_placa: "OKX-9687",
    veiculo_fipe_name_modelo: "MONTANA LS COMBO 1.4 8V ECONOFLEX 2P",
    chip_serial: "89552-38025-70011-17159",
    chip_operadora: "Vivo",
    nome: "GETRACK",
    mod_ref: "E3+",
    fab_nome: "BWS",
    NumDiasTx: 0,
    valor: 34.9,
    title: "Basico I",
    veiculo_cadastro: "2022-08-22T23:17:14.000Z",
    veiculo_id: 9804
  }
];

export class ClientRepository {


  async getClients(): Promise<Client[]> {
    const { data } = await httpFinances<any, any>(
      `invoices/clients`,
      "GET"
    );
    return data;
  }


  async getClientInvoices(client_id: number): Promise<Invoice[]> {
    const { data } = await httpFinances<any, any>(
      `invoices/clients-invoices?client_id=${client_id}`,
      "GET"
    );
    return data;
  }

  async getClientVehicles(client_id: number): Promise<Vehicle[]> {
    const { data } = await httpFinances<any, any>(
      `invoices/clients-vehicles?client_id=${client_id}`,
      "GET"
    );
    return data;
  }

}

export const clientRepository = new ClientRepository();