import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const Modal = styled.div`
  background: white;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e5e7eb;

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
  }

  button {
    background: none;
    border: none;
    color: #6b7280;
    cursor: pointer;
    padding: 0.25rem;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #f3f4f6;
      color: #111827;
    }
  }
`;

export const Content = styled.div`
  padding: 1.5rem;
  overflow-y: auto;
`;

export const FormGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;

  .field {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &.full {
      flex: 1 1 100%;
    }

    label {
      font-size: 0.875rem;
      font-weight: 500;
      color: #374151;
    }

    input {
      padding: 0.5rem;
      border: 1px solid #d1d5db;
      border-radius: 0.375rem;
      font-size: 0.875rem;

      &:focus {
        outline: none;
        border-color: #6366f1;
        box-shadow: 0 0 0 1px #6366f1;
      }
    }
  }
`;

export const ItemsSection = styled.div`
  h3 {
    font-size: 1rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 1rem;
  }
`;

export const ItemForm = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  align-items: flex-end;

  .field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &:first-child {
      flex: 2;
    }

    label {
      font-size: 0.875rem;
      font-weight: 500;
      color: #374151;
    }

    input {
      padding: 0.5rem;
      border: 1px solid #d1d5db;
      border-radius: 0.375rem;
      font-size: 0.875rem;

      &:focus {
        outline: none;
        border-color: #6366f1;
        box-shadow: 0 0 0 1px #6366f1;
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: #6366f1;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    height: 38px;

    &:hover {
      background: #4f46e5;
    }

    &:disabled {
      background: #e5e7eb;
      cursor: not-allowed;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;

  th {
    text-align: left;
    padding: 0.75rem;
    color: #6b7280;
    font-weight: 500;
    border-bottom: 1px solid #e5e7eb;
    background: #f9fafb;
  }

  td {
    padding: 0.75rem;
    border-bottom: 1px solid #e5e7eb;
  }

  tbody tr:hover {
    background: #f9fafb;
  }

  tfoot {
    font-weight: 600;
    
    td {
      border-top: 2px solid #e5e7eb;
      background: #f9fafb;
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 0.5rem;

  button {
    padding: 0.25rem;
    background: none;
    border: none;
    color: #6b7280;
    cursor: pointer;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #f3f4f6;
      color: #111827;
    }

    &:first-child:hover {
      color: #4f46e5;
    }

    &:last-child:hover {
      color: #dc2626;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem 1.5rem;
  border-top: 1px solid #e5e7eb;
  background: #f9fafb;

  button {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;

    &.cancel {
      background: white;
      border: 1px solid #d1d5db;
      color: #374151;

      &:hover {
        background: #f3f4f6;
      }
    }

    &.save {
      background: #6366f1;
      border: none;
      color: white;

      &:hover {
        background: #4f46e5;
      }

      &:disabled {
        background: #e5e7eb;
        cursor: not-allowed;
      }
    }
  }
`;