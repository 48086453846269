import { httpAxiosV4 } from "../../../gateways/Axios";





export async function getEquipByImei(imei: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `equip/imei?imei=${imei}`,
        "GET"
    );
    return response;
}

export async function getProviderAndAPN(APN: string, Operadora: string, Fornecedor: String): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `chip/provider_apn?APN=${APN}&Operadora=${Operadora}&Fornecedor=${Fornecedor}`,
        "GET"
    );
    return response;
}


export async function registerChipByExcel(payload: iChipsExcelServer): Promise<any> {
    const { data } = await httpAxiosV4<iChipsExcelServer, any>(
        `chip/register-chip-excel`,
        "POST",
        payload
    );
    return data;
}

export interface iChipInfo {
    chip_id: string;
    chip_idempresa: string;
    chip_serial: string;
    chip_linha: string;
    chip_fornecedor: string;
    chip_apn: string;
    chip_operadora: string;
    chip_franquia: string;
    chip_preco: string;
    chip_data_habilitado: string;
    chip_data_instalado: string;
    chip_emuso: string;
    chip_iddisp: string;
    chip_imei: string;
    contract_status: string;
    chip_obs: string;
    ativo: string;
    DtAtivacao: string;
    equip_imei: string;
    nome: string;
};



export interface iChipExcel {
    id: number
    IMEI: string
    Num: string
    ICCID: string
    Operadora: string
    APN: string
    Fornecedor: string
    Franquia: string

}

export interface iChipsListExcel {
    id: number
    fornecedor: string
    apn: string
    operadora: string
    chip_idempresa: number
    status: string
    chip_serial: string
    chip_linha: string
    chip_fornecedor: number
    chip_apn: number
    chip_operadora: string
    chip_franquia: string
    chip_imei: string
    chip_iddisp: number
}

export interface iChipsExcelServer {
    chip_idempresa: number
    chip_serial: string
    chip_linha: string
    chip_fornecedor: number
    chip_apn: number
    chip_operadora: string
    chip_franquia: string
    chip_imei: string
    chip_iddisp: number
}
