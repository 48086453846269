import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  background-color: #f9fafb;
  min-height: calc(100vh - 4rem);
  display: flex;
  justify-content: center;
  padding: 1.5rem;
`;

export const ContentWrapper = styled.div`
  max-width: 1400px;
  width: 100%;
`;

export const TableActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const SearchContainer = styled.div`
  position: relative;
  width: 300px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  
  &:focus {
    outline: none;
    border-color: #6366f1;
    box-shadow: 0 0 0 1px #6366f1;
  }
`;

export const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  background: white;
  color: #6b7280;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #f3f4f6;
    color: #111827;
  }

  &:disabled {
    background: #f3f4f6;
    cursor: not-allowed;
  }

  .spinning {
    animation: ${spin} 1s linear infinite;
  }
`;

export const BulkActionSelect = styled.select`
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #374151;
  background-color: white;
  cursor: pointer;
  min-width: 200px;

  &:focus {
    outline: none;
    border-color: #6366f1;
    box-shadow: 0 0 0 1px #6366f1;
  }

  &:disabled {
    background-color: #f3f4f6;
    cursor: not-allowed;
  }
`;

export const Summary = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const FilterChips = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const FilterChip = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid #e5e7eb;
  background: ${props => props.active ? '#f3f4f6' : 'white'};

  &.success {
    color: #059669;
    border-color: ${props => props.active ? '#059669' : '#e5e7eb'};
    background: ${props => props.active ? '#dcfce7' : 'white'};
  }

  &.warning {
    color: #dc2626;
    border-color: ${props => props.active ? '#dc2626' : '#e5e7eb'};
    background: ${props => props.active ? '#fee2e2' : 'white'};
  }

  &.neutral {
    color: #6b7280;
    border-color: ${props => props.active ? '#6b7280' : '#e5e7eb'};
    background: ${props => props.active ? '#f3f4f6' : 'white'};
  }

  &.danger {
    color: #991b1b;
    border-color: ${props => props.active ? '#991b1b' : '#e5e7eb'};
    background: ${props => props.active ? '#fef2f2' : 'white'};
  }

  &.info {
    color: #1d4ed8;
    border-color: ${props => props.active ? '#1d4ed8' : '#e5e7eb'};
    background: ${props => props.active ? '#eff6ff' : 'white'};
  }

  &:hover {
    background: ${props => props.active ? '' : '#f9fafb'};
  }
`;

export const SummaryCard = styled.div`
  background: white;
  padding: 1.25rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 1rem;

  .icon {
    color: #6366f1;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .label {
    font-size: 0.875rem;
    color: #6b7280;
  }

  .value {
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
  }
`;

export const Table = styled.table`
  width: 100%;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-collapse: collapse;
`;

export const Th = styled.th<{ width?: string }>`
  padding: 0.75rem 1rem;
  text-align: left;
  font-weight: 500;
  color: #6b7280;
  border-bottom: 1px solid #e5e7eb;
  background-color: #f9fafb;
  width: ${props => props.width || 'auto'};

  &.sortable {
    cursor: pointer;
    user-select: none;
    /* display: flex; */
    align-items: center;
    gap: 0.5rem;

    &:hover {
      background-color: #f3f4f6;
    }

    .sort-icon {
      color: #9ca3af;
      font-size: 0.75rem;
    }
  }

  &:first-child {
    border-top-left-radius: 0.5rem;
  }

  &:last-child {
    border-top-right-radius: 0.5rem;
  }
`;

export const Td = styled.td`
  padding: 1rem;
  color: #111827;
  border-bottom: 1px solid #e5e7eb;
  vertical-align: top;
`;

export const StatusBadge = styled.span<{ status: string }>`
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: ${props => {
    switch (props.status) {
      case 'PAGO':
        return '#dcfce7';
      case 'INADIMPLENTE':
        return '#fee2e2';
      default:
        return '#f3f4f6';
    }
  }};
  color: ${props => {
    switch (props.status) {
      case 'PAGO':
        return '#166534';
      case 'INADIMPLENTE':
        return '#991b1b';
      default:
        return '#374151';
    }
  }};
`;

export const PaymentType = styled.span<{ type: number }>`
  font-size: 0.75rem;
  color: ${props => props.type === 0 ? '#6b7280' : '#111827'};
`;

export const NotificationIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  .icon-with-count {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: #6366f1;

    span {
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  svg {
    color: #d1d5db;

    &.enabled {
      color: #6366f1;
    }

    &.warning {
      color: #dc2626;
    }
  }
`;

export const ClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .name {
    font-weight: 500;
    color: #111827;
  }

  .cpf {
    font-size: 0.875rem;
    color: #6b7280;
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .phone {
    font-weight: 500;
    color: #111827;
  }

  .email {
    font-size: 0.875rem;
    color: #6b7280;
  }
`;