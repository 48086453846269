import React, { useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import styled from 'styled-components';
import InputFieldForm from '../../../components/datagridComp/InputFieldForm';
import SelectFieldForm from '../../../components/datagridComp/SelectFieldForm';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { AuthConfig } from '../../../Config/AuthContext';
import { StorageConfig } from '../../../Config/StorageContext';
import { CancelButton, IconButton, MarginTop, Spinner, SubmitButton } from '../../StylePages';
import { extractErrorMessage } from '../../../utils/CustomErros';
import { Dayjs } from 'dayjs';
import { dtExpireInDays, formatDateToBd } from '../../../utils/Converter';
import { itemDriver, itemPayloadCreateDriver, itemPayloadEditDriver, sendCreateDriver, sendEditDriver, updatePassword } from '../repository/driversRepository';
import DatePickerInput from '../../../components/datagridComp/DatePickerInput';
import { OctagonX, SaveIcon, SquareAsteriskIcon } from 'lucide-react';





interface IOption {
    value: string;
    label: string;
}


const listCategory: IOption[] = [
    { value: "A", label: "Categoria A" },
    { value: "B", label: "Categoria B" },
    { value: "C", label: "Categoria C" },
    { value: "D", label: "Categoria D" },
    { value: "E", label: "Categoria E" },
    { value: "AB", label: "Categoria AB" },
    { value: "AC", label: "Categoria AC" },
    { value: "AD", label: "Categoria AD" },
    { value: "AE", label: "Categoria AE" },
    { value: "BE", label: "Categoria BE" },
    { value: "CE", label: "Categoria CE" },
    { value: "DE", label: "Categoria DE" },
]

const optionsEmpresaType: IOption[] = [
    { value: '3', label: 'Usuário' },
    { value: '2', label: 'Administrador' },
];

const Title = styled.h1`
font-size: 24px;
color: #333;
text-align: center;
margin-bottom: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%; /* Ocupa a largura total do componente pai */
  margin-top: 5px;
  margin-bottom: 5px;
`;


interface BoxProps {
    width?: string;
}

const Box = styled.div<BoxProps>`
    min-width: ${(props) => props.width || '100%'};
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 20px;
`;




const ErrorMessage = styled.span`
  font-size: 12px;
  color: red;
  margin-top: 4px;
`;

const userSchema = yup.object().shape({
    DriverName: yup
        .string()
        .required('O nome completo é obrigatório'),
    email: yup
        .string()
        .email('E-mail inválido')
        .required('O e-mail é obrigatório'),
    tel: yup
        .string()
        .required('O telefone é obrigatório')
        .matches(
            /^\d{10,11}$/,
            'O telefone deve conter 10 ou 11 dígitos numéricos'
        ),
    login: yup
        .string()
        .required('O login é obrigatório'),
    pass: yup
        .string()
        .required('A senha é obrigatória')
        .min(4, 'A senha deve ter pelo menos 4 caracteres'),
    password_confirme: yup
        .string()
        .oneOf([yup.ref('pass')], 'As senhas devem ser iguais')
        .required('A confirmação de senha é obrigatória')
});
const userUpdateSchema = yup.object().shape({
    DriverName: yup
        .string()
        .required('O nome completo é obrigatório'),
    email: yup
        .string()
        .email('E-mail inválido')
        .required('O e-mail é obrigatório'),
    tel: yup
        .string()
        .required('O telefone é obrigatório')
        .matches(
            /^\d{10,11}$/,
            'O telefone deve conter 10 ou 11 dígitos numéricos'
        ),
    login: yup
        .string()
        .required('O login é obrigatório')
});

const passwordSchema = yup.object().shape({
    pass: yup
        .string()
        .required('A senha é obrigatória')
        .min(4, 'A senha deve ter pelo menos 4 caracteres'),
    password_confirme: yup
        .string()
        .oneOf([yup.ref('pass')], 'As senhas devem ser iguais')
        .required('A confirmação de senha é obrigatória')
});


interface FormProps {
    initialData?: itemDriver;
    onCancel: () => void;
    onUpdate?: () => void;
    onEditReturn?: (value: itemDriver) => void;
    uuid: string;
}


let userRule = 0;

const FormDriver: React.FC<FormProps> = ({ initialData, onUpdate, onCancel, uuid }) => {
    const { clients, syncClients } = StorageConfig();
    const formRef = useRef<FormHandles>(null);
    const [category, setCategory] = React.useState<string>('AB');
    const { addNotification } = useNotifications();
    const [clientSelected, setClientSelected] = React.useState<IOption | null>(null);
    const [clientList, setClientOptions] = React.useState<IOption[]>();
    const { user } = AuthConfig();
    const [error, setError] = React.useState<string | null>(null);
    const [errorApi, setErrorApi] = React.useState<string | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [dtExpire, setDtExpire] = React.useState<Dayjs | null>(dtExpireInDays(30));
    const [showDtExpire, setShowDtExpire] = React.useState<boolean>(false);
    const [showRedefinedPassword, setShowRedefinedPassword] = React.useState<boolean>(false);
    const [showSyncClient, setShowSyncClient] = React.useState<boolean>(true);
    const [DateExpirateCNH, setDateExpirateCNH] = React.useState<Date>(new Date());
    const [DateBirthday, setDateBirthday] = React.useState<Date>(new Date());

    userRule = user?.nivel || 0;

    const sendToServer = async (payload: any, isEditing: boolean): Promise<any> => {
        try {
            setIsLoading(true);
            isEditing ? await sendEditDriver(payload) : await sendCreateDriver(payload);
            let msg = isEditing ? 'Cadastro atualizado com sucesso' : 'Cadastro criado com sucesso';
            let title = isEditing ? 'Cadastro Atualizado' : 'Cadastro Criado';
            addNotification(title, msg, 'success');
            formRef.current?.reset();
            onUpdate?.();
        } catch (error: any) {
            console.log(error);
            let message = extractErrorMessage(error)
            setErrorApi(message);
        } finally {
            setIsLoading(false);
        }

    };
    const sendNewPasswordToServer = async (payload: any): Promise<any> => {
        try {
            setIsLoading(true);
            await updatePassword(payload.id, payload.pass)
            addNotification('Senha Atualizada', 'Senha atualizada com sucesso', 'success');
            formRef.current?.reset();
            onUpdate?.();
        } catch (error: any) {
            console.log(error);
            let message = extractErrorMessage(error)
            setErrorApi(message);
        } finally {
            setIsLoading(false);
        }

    };

    const handleSubmit = async (data: any) => {
        try {
            formRef.current?.setErrors({});
            const isEditing = !!initialData;
            data.id = initialData?.id || 0;
            data.tel = data.tel.replace(/\D/g, '');

            isEditing ? await userUpdateSchema.validate(data, { abortEarly: false })
                : await userSchema.validate(data, { abortEarly: false });


            if (isEditing) {
                let payload: itemPayloadEditDriver = {
                    DriverCard: data.DriverCard || '',
                    DriverName: data.DriverName,
                    category: category || 'AB',
                    cnhValidate: formatDateToBd(DateExpirateCNH),
                    cpf: data.cpf || '',
                    dtniver: formatDateToBd(DateBirthday),
                    email: data.email || '',
                    numberCNH: data.numberCNH || '',
                    tel: data.tel || '',
                    ender: data.ender || '',
                    login: data.login,
                    id: data.id,
                    idLogin: initialData?.idLogin || 0,
                    prev_login: initialData?.login || '',
                    password: initialData?.idLogin ? null : data.pass,
                };
                await sendToServer(payload, isEditing);
                return
            }
            let payload: itemPayloadCreateDriver = {
                DriverCard: data.DriverCard || '',
                DriverName: data.DriverName,
                category: category || 'AB',
                cnhValidate: formatDateToBd(DateExpirateCNH),
                cpf: data.cpf || '',
                dtniver: formatDateToBd(DateBirthday),
                email: data.email || '',
                numberCNH: data.numberCNH || '',
                tel: data.tel || '',
                ender: data.address || '',
                login: data.login,
                password: data.pass
            };
            await sendToServer(payload, isEditing);
        } catch (err) {
            console.log(err)
            if (err instanceof yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    };

    const handleSavePassword = async (data: any) => {
        try {
            formRef.current?.setErrors({});
            await passwordSchema.validate(data, { abortEarly: false })
            let payload: any = {
                id: initialData?.idLogin || 0,
                pass: data.pass,
            };
            sendNewPasswordToServer(payload);
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    };

    const handleChange = (selected: IOption | null) => {
        setError(null);
        setClientSelected(selected);
    };



    const recoverInitialData = async () => {

    }



    React.useEffect(() => {
        setErrorApi(null)
        if (initialData) {
            recoverInitialData()
        } else {
            formRef.current?.reset()
            setClientSelected(null)
            setShowDtExpire(false)
            setDtExpire(dtExpireInDays(30))
        }
        formRef.current?.setErrors({})
    }, [uuid])



    return (
        <>{showRedefinedPassword ?
            <FormContainer>
                <Title>Redefinir Senha</Title>
                <Form ref={formRef} onSubmit={handleSavePassword} placeholder={''}>
                    <Row>
                        <InputFieldForm label="Senha*" name="pass" type="password" />
                        <InputFieldForm label="Confirme a Senha*" name="password_confirme" type="password" />
                    </Row>
                    <ActionButtons>
                        <CancelButton type="button" onClick={() => setShowRedefinedPassword(false)}>Cancelar</CancelButton>
                        <SubmitButton type="submit" isLoading={isLoading} disabled={isLoading}>
                            {isLoading ? <>
                                <Spinner />
                                Salvando, aguarde...
                            </> : "Salvar"}
                        </SubmitButton>
                    </ActionButtons>
                </Form>
            </FormContainer> :
            <FormContainer>
                <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData} placeholder={''}>
                    <Row>
                        <InputFieldForm label="Nome Completo*" name="DriverName" />
                        <InputFieldForm label="CPF*" name="cpf" width='250px' />
                    </Row>
                    <Row>
                        <InputFieldForm label="Endereço" name="ender" />
                        <DatePickerInput
                            width='250px'
                            label='Data de Nascimento'
                            value={DateBirthday}
                            onChange={(value) => setDateBirthday(value)} />
                    </Row>
                    <Row>
                        <InputFieldForm label="E-mail*" name="email" type="email" />
                        <InputFieldForm label="WhatsApp/Telefone*" name="tel" />
                        <InputFieldForm label="Ibutton/Tag" name="DriverCard" />

                    </Row>
                    <Row>
                        <InputFieldForm label="CNH" name="numberCNH" type="number" />
                        <SelectFieldForm
                            options={listCategory}
                            label="Categoria"
                            name="category" // Alterado de empresa_tipo para cliente_tipo
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                        />
                        <DatePickerInput
                            label='Vencimento CNH'
                            value={DateExpirateCNH}
                            onChange={(value) => setDateExpirateCNH(value)} />
                    </Row>
                    <Row>
                        <InputFieldForm
                            label="Login*"
                            name="login"
                            width={initialData && initialData.idLogin ? '210px' : '100%'}
                        />

                        {(!initialData || initialData.idLogin === 0 || initialData.idLogin === null) && (
                            <>
                                <InputFieldForm label="Senha*" name="pass" type="password" />
                                <InputFieldForm label="Confirme a Senha*" name="password_confirme" type="password" />
                            </>
                        )}
                    </Row>

                    <MarginTop />
                    {errorApi && <Row>
                        <ErrorMessage>{errorApi}</ErrorMessage>
                    </Row>}
                    <ActionButtons>
                        <IconButton onClick={onCancel}>
                            <OctagonX size={20} />
                            <span>Cancelar</span>
                        </IconButton>
                        <IconButton type="submit" disabled={isLoading}>
                            {isLoading ? <>
                                <Spinner />
                                Salvando, aguarde...
                            </> :
                                <> <SaveIcon size={20} />
                                    <span>Salvar</span></>}
                        </IconButton>
                        {(initialData && initialData.idLogin) &&
                            <IconButton onClick={() => setShowRedefinedPassword(true)} type="submit" disabled={isLoading}>
                                <SquareAsteriskIcon size={20} />
                                <span>Redefinir Senha</span>
                            </IconButton>}
                    </ActionButtons>
                </Form>

            </FormContainer>
        }
        </>);
};

export default FormDriver;


