import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const TabHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const CompactSummary = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.75rem 1rem;
  background: #f9fafb;
  border-radius: 0.5rem;
  width: fit-content;
  min-width: 420px;

  .summary-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    
    .label {
      font-size: 0.75rem;
      color: #6b7280;
    }
    
    .value {
      font-size: 0.875rem;
      font-weight: 600;
      color: #111827;
      
      &.warning {
        color: #dc2626;
      }
    }
  }

  .divider {
    width: 1px;
    height: 2rem;
    background: #e5e7eb;
  }
`;

export const ActionBar = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const SearchInput = styled.div`
  position: relative;
  width: 250px;

  svg {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #6b7280;
  }

  input {
    width: 100%;
    padding: 0.5rem 0.75rem 0.5rem 2.25rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    
    &:focus {
      outline: none;
      border-color: #6366f1;
      box-shadow: 0 0 0 1px #6366f1;
    }

    &::placeholder {
      color: #9ca3af;
    }
  }
`;

export const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  background: white;
  color: #6b7280;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #f3f4f6;
    color: #111827;
  }

  &:disabled {
    background: #f3f4f6;
    cursor: not-allowed;
  }

  .spinning {
    animation: ${spin} 1s linear infinite;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;

  th {
    text-align: left;
    padding: 0.625rem 0.75rem;
    color: #6b7280;
    font-weight: 500;
    border-bottom: 1px solid #e5e7eb;
    background: #f9fafb;
    white-space: nowrap;
  }

  td {
    padding: 0.625rem 0.75rem;
    border-bottom: 1px solid #e5e7eb;
    white-space: nowrap;
  }

  tbody tr:hover {
    background: #f9fafb;
  }
`;

export const VehiclePlate = styled.div`
  font-family: monospace;
  font-size: 0.875rem;
  font-weight: 600;
  color: #111827;
  letter-spacing: 0.05em;
`;

export const EquipmentInfo = styled.div`
  .model {
    font-weight: 500;
    color: #111827;
  }

  .imei {
    font-size: 0.75rem;
    color: #6b7280;
    font-family: monospace;
  }
`;

export const ChipInfo = styled.div`
  .operator {
    font-weight: 500;
    color: #111827;
  }

  .serial {
    font-size: 0.75rem;
    color: #6b7280;
    font-family: monospace;
  }
`;

export const TransmissionInfo = styled.div<{ recent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  color: ${props => props.recent ? '#059669' : '#111827'};

  .days {
    font-size: 0.75rem;
    color: #6b7280;
  }
`;