import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
`;

export const TableContainer = styled.div`
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: auto;
    height: 72vh;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`;

export const Th = styled.th<{ width?: string }>`
  background: #f8f9fa;
  padding: 12px 16px;
  text-align: left;
  font-weight: 600;
  color: #1a1a1a;
  border-bottom: 2px solid #e9ecef;
  position: relative;
  width: ${props => props.width || 'auto'};
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #e9ecef;
    
    .resizer {
      background-color: #6366f1;
    }
  }

  &.checkbox-column {
    width: 40px;
    padding: 12px 8px;
    cursor: default;
  }

  &.actions-column {
    width: 60px;
    text-align: center;
  }
`;

export const Td = styled.td`
  padding: 12px 16px;
  border-bottom: 1px solid #e9ecef;
  color: #4a5568;
  height: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;

  &.checkbox-column {
    padding: 12px 8px;
  }

  &.actions-column {
    text-align: center;
    padding: 12px 8px;
  }
`;

export const Tr = styled.tr`
  &:hover {
    background-color: #f8f9fa;
  }
`;

export const Resizer = styled.div.attrs({ className: 'resizer' })`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background-color: transparent;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  
  &:hover {
    background-color: #6366f1;
  }
`;

export const SearchContainer = styled.div`
  padding: 16px;
  background: white;
  border-bottom: 1px solid #e9ecef;
`;

export const SearchWrapper = styled.div`
  position: relative;
  max-width: 400px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 8px 12px 8px 36px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.2s;
  
  &:focus {
    outline: none;
    border-color: #6366f1;
    box-shadow: 0 0 0 1px #6366f1;
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 200px;
`;

export const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #6b7280;
  font-size: 0.875rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  
  &:hover {
    background-color: #f3f4f6;
  }

  svg {
    margin-top: 4px;
  }
`;

export const DeviceInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  
  
  .device-id {
    font-weight: 500;
  }
  
  .device-manufacturer {
    font-size: 12px;
    color: #6b7280;
  }
`;

export const ChipInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  
  
  .chip-serial {
    font-weight: 500;
  }
  
  .chip-details {
    font-size: 12px;
    color: #6b7280;
  }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const ColumnSelector = styled.div`
  position: relative;
  display: inline-block;
`;

export const ColumnSelectorButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 14px;
  color: #4a5568;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #f8f9fa;
  }
`;

export const ColumnSelectorDropdown = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 4px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 200px;
`;

export const ColumnSelectorItem = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f8f9fa;
  }

  span {
    font-size: 14px;
    color: #4a5568;
  }
`;

export const ActionButton = styled.button`
  padding: 4px;
  border-radius: 4px;
  color: #6b7280;
  transition: all 0.2s;

  &:hover {
    color: #4a5568;
    background-color: #f3f4f6;
  }
`;

export const SelectButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background: #f3f4f6;
  border-radius: 6px;
  color: #6b7280;
  font-size: 14px;
  transition: all 0.2s;
  border: 1px dashed #d1d5db;

  &:hover {
    background: #e5e7eb;
    border-color: #9ca3af;
    color: #4b5563;
  }

  svg {
    color: #6b7280;
  }
`;

export const VehicleInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  
  .vehicle-plate {
    font-weight: 500;
  }
  
  .vehicle-model {
    font-size: 12px;
    color: #6b7280;
  }
`;