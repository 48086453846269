import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: inline-flex;
`;

export const Content = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #1f2937;
  color: white;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  z-index: 50;
  
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px;
    border-style: solid;
    border-color: #1f2937 transparent transparent transparent;
  }
`;