


import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import '../Styles/MapStyles.css';
import { EventsData, JourneyData } from "../repository/MapsRepo";
import { fi } from "date-fns/locale";
import { format } from "date-fns";
import { createRoot } from "react-dom/client";
import { formateTime } from "./tools";
import { formatDateToScreenString } from "../../../utils/Converter";

function createAdvanceMarkerStop(
    map: google.maps.Map,
    event: JourneyData
): google.maps.marker.AdvancedMarkerElement {


    const labelContent = `
        <div style="position: relative; width: 40px; height: 40px;">                
                <img src="assets/icons_alerts/park_dark.png" style=" z-index: 0; width: 32px; height: 32px; position: absolute; top: 4px; left: 4px;" />           
        </div> 
    `;


    const container = document.createElement("div");
    const root = createRoot(container);
    root.render(<div dangerouslySetInnerHTML={{ __html: labelContent }} />);


    const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: event.locationStart.lat, lng: event.locationStart.lng },
        map: map,
        content: container,
        title: 'Parada',
    });


    const iconEvent = `
                    <div style="position: relative; width: 35px; height: 35px;">
                        <img src="assets/icons_alerts/park_dark.png" style=" width: 32px; height: 32px;" />            
                     </div>`;

    const EventInfo = `
    <div style="display: flex;
        align-items: center;
        justify-content: space-around;
        padding-top: 2px;">
            <div style="display: flex;">
                ${iconEvent}  
                <div style="margin-left: 10px;">
                    <h3 style="font-size: 13px;font-weight: 600;">${formatDateToScreenString(event.dtStartDate)}</h3>
                    <div style="font-size: 12px;padding-top:3px;font-weight: 600;">Parado por ${formateTime(Number(event.duration))}</div>                
                </div>
            </div>  
        </div>`;


    const contentInfo = `
    <div style="background-color: #f7f7f7; color: #333; padding: 10px; border-radius: 10px; width: 300px;min-heght:150px">
 
       ${EventInfo}
   
    </div>
`;

    const infoWindow = new google.maps.InfoWindow({
        headerDisabled: false,
        content: contentInfo,
        headerContent: `Parada`,
    });

    marker.addListener('click', () => {
        infoWindow.open(map, marker);
    });


    return marker;
}

export default createAdvanceMarkerStop;