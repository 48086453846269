import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  border-radius: 0.5rem;
  margin-top: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e5e7eb;

  .title {
    display: flex;
    align-items: center;
    gap: 1rem;

    h2 {
      font-size: 1.25rem;
      font-weight: 600;
      color: #111827;
    }
  }
`;

export const Content = styled.div`
  padding: 1.5rem;
`;

export const Summary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;

  .info-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }

  .info-item {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;

    .icon {
      color: #6366f1;
      flex-shrink: 0;
      margin-top: 0.25rem;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  }

  .amount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.25rem;
  }

  .label {
    font-size: 0.75rem;
    color: #6b7280;
  }

  .value {
    font-size: 0.875rem;
    color: #111827;
  }

  .amount .value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
`;

export const ActionButton = styled.button<{ variant: 'primary' | 'secondary' | 'danger' | 'success' }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  ${props => {
    switch (props.variant) {
      case 'primary':
        return `
          background: #6366f1;
          color: white;
          border: none;
          &:hover { background: #4f46e5; }
        `;
      case 'secondary':
        return `
          background: white;
          color: #374151;
          border: 1px solid #d1d5db;
          &:hover { background: #f3f4f6; }
        `;
      case 'danger':
        return `
          background: #fee2e2;
          color: #991b1b;
          border: none;
          &:hover { background: #fecaca; }
        `;
      case 'success':
        return `
          background: #dcfce7;
          color: #166534;
          border: none;
          &:hover { background: #bbf7d0; }
        `;
      default:
        return '';
    }
  }}
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabList = styled.div`
  display: flex;
  gap: 0.5rem;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 1rem;
`;

export const TabButton = styled.button<{ active: boolean }>`
  padding: 0.75rem 1rem;
  background: none;
  border: none;
  border-bottom: 2px solid ${props => props.active ? '#6366f1' : 'transparent'};
  color: ${props => props.active ? '#111827' : '#6b7280'};
  font-weight: ${props => props.active ? '600' : '500'};
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #111827;
  }
`;

export const TabContent = styled.div`
  min-height: 300px;
`;

export const InvoiceStatus = styled.span<{ status: 'paid' | 'pending' }>`
  display: inline-flex;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: ${props => props.status === 'paid' ? '#dcfce7' : '#fee2e2'};
  color: ${props => props.status === 'paid' ? '#166534' : '#991b1b'};
`;