import axios from "axios";

type httpMethods = "GET" | "POST" | "PATCH" | "DELETE" | "PUT";

export const httpAxiosV4 = async <T, K>(
    route: string,
    method: httpMethods,
    body?: T
) => {
    const token = window.localStorage.getItem("token");

    try {
        return await axios<K>(
            `${process.env.REACT_APP_XSMART_APIV4}/${route}`,
            body
                ? {
                    method,
                    data: body,
                    headers: { Authorization: `Bearer ${token}` },
                }
                : { method, headers: { Authorization: `Bearer ${token}` } }
        );
    } catch (err: any) {
        console.log(err);
        // Verifica se o erro é devido a token inválido
        if (err.response?.status === 401) {
            // Redireciona para a página de login
            window.localStorage.removeItem("dados_token")
            window.localStorage.removeItem("token")
            window.location.href = "/login";
        }
        throw err; // Repassa o erro para o chamador lidar
    }
};

export const httpReport = async <T, K>(
    route: string,
    method: httpMethods,
    body?: T,
    responseType: 'json' | 'blob' = 'json'
) => {
    const token = window.localStorage.getItem("token");

    try {
        return await axios<K>(
            `${process.env.REACT_APP_REPORT_API}/${route}`,
            body
                ? {
                    method,
                    data: body,
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: responseType,
                }
                : { method, headers: { Authorization: `Bearer ${token}` } }
        );
    } catch (err) {
        throw err;
    }
};


export const httpFinances = async <T, K>(
    route: string,
    method: httpMethods,
    body?: T,
) => {
    const token = window.localStorage.getItem("token");

    try {
        return await axios<K>(
            `${process.env.REACT_APP_XSMART_APIFINANCE}/${route}`,
            body
                ? {
                    method,
                    data: body,
                    headers: { Authorization: `Bearer ${token}` },
                }
                : { method, headers: { Authorization: `Bearer ${token}` } }
        );
    } catch (err) {
        throw err;
    }
};

export const httpGateway = async <T, K>(
    route: string,
    method: httpMethods,
    body?: T
) => {
    const token = window.localStorage.getItem("token");

    try {
        return await axios<K>(
            `${route}`,
            body
                ? {
                    method,
                    data: body,
                    headers: { Authorization: `Bearer ${token}` },
                }
                : { method, headers: { Authorization: `Bearer ${token}` } }
        );
    } catch (err) {
        throw err;
    }
};