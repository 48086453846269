import { Column, SelectColumn } from 'react-data-grid';
import ColumnStatus from '../../../components/datagridComp/ColumnStatus';
import { iChipsListExcel } from '../repository/ChipRepository';


export const getColumnsChipsExcel = (): Column<iChipsListExcel>[] => {
    return [
        // Coluna para seleção
        {
            ...SelectColumn,
            name: 'Selecionar'
        },
        // Coluna para ações
        {
            key: 'status',
            name: 'Status',
            width: 200,
            renderCell(props: any) {
                return <ColumnStatus
                    messageLabel={props.row.status}
                    messageType={
                        props.row.status.includes('Erro') ? 'ERROR' :
                            props.row.status.includes('Salvo') ? 'SUCCESS' : ''}
                />;
            },
        },
        {
            key: 'fornecedor',
            name: 'Fornecedor',
            width: 100,
        },
        {
            key: 'apn',
            name: 'APN',
            width: 200,
        },
        {
            key: 'operadora',
            name: 'Operadora',
            width: 200,
        },
        {
            key: 'chip_imei',
            name: 'IMEI',
            width: 150,
        },
        {
            key: 'chip_iddisp',
            name: 'ID Disp',
            width: 95,
        },
        {
            key: 'chip_linha',
            name: 'Número',
            width: 150,
        },

        {
            key: 'chip_serial',
            name: 'ICCID',
            width: 180,
        },
        {
            key: 'chip_fornecedor',
            name: 'Cód. Fornecedor',
            width: 95,
        },
        {
            key: 'chip_apn',
            name: 'Cód. APN',
            width: 95,
        },

    ];
};

