import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
  color: #6b7280;
  
  svg {
    opacity: 0.5;
  }
  
  span {
    font-size: 0.875rem;
  }
`;