import React, { useEffect, useRef } from 'react';
import { styled } from 'styled-components';
import { EventsData, getApiSoptRoutes, getApiTrajetos, JourneyData, TrajectData } from '../repository/MapsRepo';
import { MapConfig } from '../utilsMap/mapContext';
import { useGoogleMap } from '../../../GoogleMaps/GoogleMapProvider';
import { formatDateDayJs, formatDateToScreenDayJs } from '../../../utils/Converter';
import createAdvanceMarkerStop from '../utilsMap/createAdvanceMarkerStop';
import { useConfig } from '../../../Config/configContext';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { ALL_VEHICLES, OPTIONS_TRAJETOS, TAB_HISTORY_EVENTS, TAB_HISTORY_TRAJETOS } from '../utilsMap/ConstMaps';
import Sidebar from '../../../components/datagridComp/SideBar';
import OpcoesHistory from '../components/OpcoesHistory';
import SummaryTrajectComp from '../components/SummaryTrajectComp';
import TrajectsComp from '../components/TrajectsComp';
import { formateTime, processTrajectoryData } from '../utilsMap/tools';
import SummaryTrajectWithPositionsComp from '../components/SummaryTrajectWithPositionsComp';
import SpeedTrajectsChart from '../components/GraphicsMap/SpeedTrajectsChart';
import MetricsDisplay, { MetricsProps } from '../components/MetricsDisplay';
import { format } from 'path';
import { getReportHistory, getReportHistoryToMap } from '../../Repoter/repository/ReportRepo';
import { AuthConfig } from '../../../Config/AuthContext';
import { LoadingPrintModal } from '../../../components/LoadingPrintModal';
import { useNotifications } from '../../../Notifications/NotificationContext';

// Container geral do layout
const LayoutContainer = styled.div`
  display: flex;
  height: 88vh;
`;

const LeftContainer = styled.div<{ isExpanded: boolean }>`
  width: ${({ isExpanded }) => (isExpanded ? '410px' : '328px')};
  padding-top: 5px;
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 88vh;
  align-items: center;
  overflow: auto;
`;

const ContainerMaps = styled.div`
  flex-grow: 1;
  padding: 5px;
  transition: margin-left 0.3s; 
`;

const MapContainer = styled.div`
  height: 100%;
  width: 100%; 
  position: relative; 
`;

const VehiclesContainer = styled.div`
 display: flex;
  gap   : 5px;
  height: 88vh;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;


const SummaryInfo = styled.div`
    font-size: 12px;
    color: #666;
    padding-left: 5px;
    display: flex;
    align-items: start;
    width: 100%;
`;

let loadingTrajects = false;

const TabHistoryV2: React.FC = () => {
    const { isStopBySpeed, LoadEventsInReportMap, LoadTrajectsInReportMap, speedLimitShowPositions, dateStartFilterMap, dateEndFilterMap } = useConfig();
    const { vehicleSelected, modeActive, actionPressButton, tabHistoryActive, updateConfig } = MapConfig();
    const { user } = AuthConfig();
    const { addNotification } = useNotifications();

    const [trajects, setTrajects] = React.useState<TrajectData[]>([])
    const [eventsData, setEventsData] = React.useState<EventsData[]>([])
    const [journeyData, setJourneyData] = React.useState<JourneyData[]>([])
    const markersRef = useRef<google.maps.Marker[]>([]);
    const markersEventsRef = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);
    const polyLineRef = useRef<google.maps.Polyline>();
    const polyLineFilterRef = useRef<google.maps.Polyline>();
    const [metrics, setMetrics] = React.useState<MetricsProps>();

    const [isModalPrintOpen, setModalPrintOpen] = React.useState(false);

    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();
    const handleCloseSideBar = () => setSideContent(undefined);

    //----------------Maps
    const map = useGoogleMap();
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const clustererRef = useRef<MarkerClusterer | null>(null);


    useEffect(() => {
        console.log('Map In History')
        if (map && mapContainerRef.current) {
            // Associa o mapa ao contêiner específico desta tela
            map.setOptions({
                center: { lat: -15.7801, lng: -47.9292 }, // Ajuste conforme necessário
                zoom: 4,
            });

            // Move o mapa para o contêiner correto
            mapContainerRef.current.appendChild(map.getDiv());
            google.maps.event.trigger(map, 'resize');
            // Initialize MarkerClusterer
            clustererRef.current = new MarkerClusterer({ map });

        }
    }, [map]);

    const handleClose = () => {
        updateConfig({ vehicleSelected: null, modeActive: ALL_VEHICLES, tabActive: 'MAPA' })
    }


    const clearMap = () => {
        console.log('Clear Map')
        markersRef.current.forEach(marker => marker.setMap(null));
        markersRef.current = [];
        markersEventsRef.current.forEach(marker => marker.map = null);
        markersEventsRef.current = [];
        if (polyLineRef.current) {
            polyLineRef.current.setMap(null);
            polyLineRef.current = undefined;
        }
        if (polyLineFilterRef.current) {
            polyLineFilterRef.current.setMap(null);
            polyLineFilterRef.current = undefined;
        }
        if (clustererRef.current) {
            clustererRef.current.clearMarkers();
        }
    }



    const handleIconClick = (action: string) => {
        switch (action) {
            case 'close':
                handleClose()
                break;
            case 'settings':
                setSideContent(OPTIONS_TRAJETOS)
                break;
            case 'update':
                getDados()
                break;
            case 'clear':
                clearMap()
                break;
            default:
                console.log('Ação desconhecida');
        }
        updateConfig({ actionPressButton: null })
    };

    useEffect(() => {
        if (actionPressButton)
            handleIconClick(actionPressButton)
    }, [actionPressButton]);


    useEffect(() => {
        clearMap()
        if (map && trajects.length > 0) {
            const bounds = new google.maps.LatLngBounds();
            const path = trajects.map(t => ({ lat: parseFloat(t.lat), lng: parseFloat(t.lng) }));

            const line = new google.maps.Polyline({
                map: map,
                path,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 3,
            });

            polyLineRef.current = line;

            for (const point of path) {
                bounds.extend(point);
            }
            map.fitBounds(bounds);

            // Adicionando marcadores com InfoWindow
            trajects.forEach((t) => {
                const position = { lat: parseFloat(t.lat), lng: parseFloat(t.lng) };
                const colorMarker = getColorBasedOnSpeed(t.speed);
                if (t.speed > speedLimitShowPositions) {
                    const marker = new google.maps.Marker({
                        position,
                        map: map,
                        icon: {
                            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            scale: 3,
                            fillColor: colorMarker,
                            fillOpacity: 0.8,
                            rotation: t.dir,
                            strokeColor: colorMarker,
                            strokeWeight: 2,
                        },
                    });
                    markersRef.current.push(marker);


                    const infoWindow = new google.maps.InfoWindow({
                        content: `
                        <div>
                            <p><strong>Velocidade:</strong> ${t.speed} km/h</p>
                            <p><strong>Latitude:</strong> ${t.lat}</p>
                            <p><strong>Longitude:</strong> ${t.lng}</p>
                           
                        </div>
                    `,
                    });

                    marker.addListener('click', () => {
                        infoWindow.open(map, marker);
                    });

                    marker.setMap(map);
                }
            });

            // let resp = processTrajectoryData(trajects);
            // console.log('resp', resp)
            // setJourneyData(resp.events)
            // setMetrics({
            //     averageSpeed: resp.averageSpeed,
            //     maxSpeed: resp.maxSpeed,
            //     totalStoppedTime: resp.totalStoppedTime,
            //     totalMovingTime: resp.totalMovingTime,
            //     totalDistance: resp.totalDistance
            // })
        }
    }, [trajects]);


    /**
     * Criar os pontos de paradas no mapa
     */
    useEffect(() => {
        if (map && journeyData.length > 0) {
            journeyData.forEach((t) => {
                if (t.isStop) {
                    const marker = createAdvanceMarkerStop(map!, t);
                    markersEventsRef.current.push(marker);
                }
            });
        }
    }, [journeyData]);


    async function getTrajetos(idVehicle: number) {
        setModalPrintOpen(true)
        if (isModalPrintOpen) return;
        console.log('getTrajetos loanding...')
        try {
            const dtIni = formatDateDayJs(dateStartFilterMap)
            const dtFim = formatDateDayJs(dateEndFilterMap)


            const payload = {
                dtIni,
                dtFim,
                idveiculo: idVehicle,
                idclient: user?.idclient,
                isMoveSpeed: isStopBySpeed
            };
            const result = await getReportHistoryToMap(payload);
            setTrajects(result.positions ?? [])
            setJourneyData(result.events ?? [])

            setMetrics({
                averageSpeed: result.averageSpeed,
                maxSpeed: result.maxSpeed,
                totalStoppedTime: result.totalStoppedTime,
                totalMovingTime: result.totalMovingTime,
                totalDistance: result.totalDistance
            })

            updateConfig({
                countPositions: result.positions?.length,
                countEvents: result.events?.length
            })
        } catch (error) {
            console.error('Error getTrajetos', error)
            addNotification('', 'Erro ao carregar trajetos', 'error')
        } finally {
            setModalPrintOpen(false)
        }

    }

    function getColorBasedOnSpeed(speed: number): string {
        if (speed <= 50) return "#1100ff"; // Verde para velocidade baixa
        if (speed > 50 && speed < 80) return "#ff7300"; // Amarelo para velocidade média
        return "#FF0000"; // Vermelho para velocidade alta
    }

    const getDados = () => {
        if (vehicleSelected) {
            getTrajetos(vehicleSelected.idveiculo)
            //getEventos(vehicleSelected.idveiculo)
        }
    }


    useEffect(() => {
        getDados()
    }, [dateStartFilterMap, dateEndFilterMap]);

    const CenterMap = (lat: number, lng: number) => {
        map?.setCenter({ lat, lng });
        map?.setZoom(18);
    }

    useEffect(() => {
        console.log("Opened TabHistory")
        getDados()
        //componentUnAmount()
        return () => {
            console.log("Destruct TabHistory")
            clearMap()
        }
    }, [])


    function filterPoints(points: TrajectData[], startDate: Date, endDate: Date): TrajectData[] {
        return points.filter(point => {
            const pointDate = new Date(point.dthr_equip);
            return pointDate >= startDate && pointDate <= endDate;
        });
    }

    function filterPointsById(points: TrajectData[], startId: number, endId: number): TrajectData[] {
        return points.filter(point => {
            return point.id >= startId && point.id <= endId;
        });
    }


    const showTrajectsInMap = (params: JourneyData) => {
        //const filteredPoints = filterPoints(trajects, params.dtStartDate, params.dtEndDate);
        if (!params.idPositionStart || !params.idPositionEnd) {
            console.log('Invalid params for filtering points:', params);
            return;
        }
        const filteredPoints = filterPointsById(trajects, params.idPositionStart, params.idPositionEnd);
        const path = filteredPoints.map(({ lat, lng }) => ({ lat: parseFloat(lat), lng: parseFloat(lng) }));

        console.log('filteredPoints', filteredPoints, params)
        if (filteredPoints.length === 0) {
            console.log('No journeys found for the specified date range.');
            return;
        }

        const line = new google.maps.Polyline({
            map: map,
            path,
            geodesic: true,
            strokeColor: '#111111',
            strokeOpacity: 1.0,
            strokeWeight: 4,
            zIndex: 2
        });
        polyLineFilterRef.current?.setMap(null);
        polyLineFilterRef.current = line;

        polyLineRef.current?.setOptions({ strokeOpacity: 0.5 });

        const bounds = new google.maps.LatLngBounds();
        for (const point of path) {
            bounds.extend(point);
        }
        map?.fitBounds(bounds);

    };

    const handlePointAcion = (point: JourneyData) => {
        if (point.isStop) {
            CenterMap(point.locationStart.lat, point.locationStart.lng)
            if (polyLineFilterRef.current) {
                polyLineFilterRef.current?.setMap(null);
            }

        }
        if (!point.isStop) {
            showTrajectsInMap(point)
        }

    }


    return (
        <>
            {/*<SummaryTrajectComp events={eventsData} positions={trajects} onPointClick={handlePointAcion} />*/}
            <LayoutContainer>
                <LeftContainer isExpanded={false}>
                    <VehiclesContainer>
                        {tabHistoryActive === TAB_HISTORY_EVENTS && (
                            <>
                                <SummaryInfo>{formatDateToScreenDayJs(dateStartFilterMap)} - {formatDateToScreenDayJs(dateEndFilterMap)}</SummaryInfo>
                                <SpeedTrajectsChart data={trajects} />
                                {metrics && <MetricsDisplay
                                    averageSpeed={metrics?.averageSpeed}
                                    maxSpeed={metrics?.maxSpeed}
                                    totalStoppedTime={metrics?.totalStoppedTime}
                                    totalMovingTime={metrics?.totalMovingTime}
                                    totalDistance={metrics?.totalDistance}
                                />}
                                <SummaryTrajectWithPositionsComp
                                    journeyData={journeyData}
                                    onPointClick={handlePointAcion} />
                            </>)}
                        {tabHistoryActive === TAB_HISTORY_TRAJETOS && (
                            trajects.map((item, index) =>
                                <TrajectsComp key={index} itemData={item} />
                            )
                        )}
                    </VehiclesContainer>
                </LeftContainer>
                <ContainerMaps>
                    {metrics && <MetricsDisplay
                        averageSpeed={metrics?.averageSpeed}
                        maxSpeed={metrics?.maxSpeed}
                        totalStoppedTime={metrics?.totalStoppedTime}
                        totalMovingTime={metrics?.totalMovingTime}
                        totalDistance={metrics?.totalDistance}
                    />}
                    <MapContainer ref={mapContainerRef}></MapContainer>
                </ContainerMaps>
            </LayoutContainer>
            <Sidebar isOpen={sideContent === OPTIONS_TRAJETOS} onClose={handleCloseSideBar} title='Opções de Trajetos'>
                <OpcoesHistory />
            </Sidebar>
            <LoadingPrintModal
                isOpen={isModalPrintOpen}
                onClose={() => setModalPrintOpen(false)}
                title={`Processando Dados`}
            />
        </>
    );
};

export default TabHistoryV2;
