
import { httpAxiosV4 } from '../../../gateways/Axios';

export async function getListVehicles(): Promise<any> {
  const { data: response } = await httpAxiosV4<any, any>(
    `vehicles/company`,
    "GET"
  );
  return response;
}
